<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
        
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner  v-if="errore_caricamento == false"
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
      />
      <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
      <b-row class="mb-2">
        <b-col cols="auto" class="mr-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary" 
            class="mr-2"
            @click="$router.push({ name: 'activities-index-in-corso' })"
          >
            In Corso
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="$router.push({ name: 'activities-index-completata' })"
          >
            Completato
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mr-2"
            @click="$router.push({ name: 'activities-index-annullata' })"
          >
            Annullato
          </b-button>
        </b-col>

        <b-col cols="auto">
          <b-button variant="outline-primary" @click.prevent="clickReset()" v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
        </b-col>
        <b-col cols="auto">
          <b-button variant="outline-primary" @click.prevent="openModelSelezioneAttivitaUtente()"><feather-icon icon="UsersIcon"/></b-button>
        </b-col>
      </b-row>      

      <!-- table -->
      <vue-good-table
      mode="remote"
      ref="table_InCorso"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      :isLoading=isLoading
      :search-options="{ 
          enabled: true,
          externalQuery: searchTerm }"
      :pagination-options="{
          enabled: true,
          perPage:pageLength
      }"
      >
      
      <template slot="loadingContent">
        <div class="text-center my-3">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
              label="loading"
            />
            <div class="d-block pt-1 text-primary">Caricamento</div>
        </div>
      </template>
      
      <div slot="emptystate"> <div class="text-center py-4"> Nessuna attività presente</div> </div>

      <template
          slot="table-row"
          slot-scope="props"
      >
          <div v-if="props.column.field === 'date'">
            {{ props.row.date }} {{ props.row.time }}
          </div>

          <div v-else-if="props.column.field === 'assigned_to'">
            {{ props.row.assegnato.name }}
          </div>
          
          <span v-else-if="props.column.field === 'priority_id'">
          <b-badge :variant="statusVariant(props.row.priorita.color)">
              {{ props.row.priorita.type }}
          </b-badge>
          </span>

          <div v-else-if="props.column.field === 'type_id'">
            {{ props.row.tipo.name }}
          </div>

          <div v-else-if="props.column.field === 'nome_ricerca'">
            <div v-if="props.row.related_to == 'Customer'">
              <strong>Cliente</strong>: <a @click="gotoqui('cliente', props.row.dati.id)" v-b-popover.hover.right.html="'<strong>Ragione sociale</strong>: '+props.row.dati.billing.company_name+'<br /><strong>Nome</strong>: '+props.row.dati.user.name+'<br /><strong>Cognome</strong>: '+props.row.dati.user.surname+'<br /><strong>Cellulare</strong>: '+props.row.dati.user.mobile_phone+'<br /><strong>Email</strong>: '+props.row.dati.user.email+' '">{{ props.row.nome_ricerca }}</a>
            </div>
            <div v-else-if="props.row.related_to == 'Lead'">
              <div v-if="props.row.dati && props.row.dati.billing && props.row.dati.billing.company_name">
                <strong>Lead</strong>: <a @click="gotoqui('lead', props.row.dati.id)" v-b-popover.hover.right.html="'<strong>Ragione sociale</strong>: '+props.row.dati.billing.company_name+'<br /><strong>Nome</strong>: '+props.row.dati.name+'<br /><strong>Cognome</strong>: '+props.row.dati.surname+'<br /><strong>Cellulare</strong>: '+props.row.dati.mobile_phone+'<br /><strong>Email</strong>: '+props.row.dati.email+' '">{{ props.row.nome_ricerca }}</a>
              </div>
              <div v-else>
                <strong>Lead</strong>: <a @click="gotoqui('lead', props.row.dati.id)" v-b-popover.hover.right.html="'<strong>Ragione sociale</strong>: Non disponibile<br /><strong>Nome</strong>: '+props.row.dati.name+'<br /><strong>Cognome</strong>: '+props.row.dati.surname+'<br /><strong>Cellulare</strong>: '+props.row.dati.mobile_phone+'<br /><strong>Email</strong>: '+props.row.dati.email+' '">Nome azienda non disponibile</a>
              </div>
            </div>
            <div v-else>
              <strong>Agente</strong>: <a @click="gotoqui('agente', props.row.dati.id)" v-b-popover.hover.right.html="'<strong>Nome</strong>: '+props.row.dati.name+'<br /><strong>Cognome</strong>: '+props.row.dati.surname+'<br /><strong>Cellulare</strong>: '+props.row.dati.mobile_phone+'<br /><strong>Email</strong>: '+props.row.dati.email+' '">{{ props.row.nome_ricerca }}</a>
            </div>
          </div>

          <!-- Column: azioni -->
          <span v-else-if="props.column.field === 'azioni'">
            <div class="text-nowrap">
              <feather-icon
                icon="ArrowRightIcon"
                class="cursor-pointer text-primary"
                size="21"
                v-b-tooltip.hover.v-primary
                title="Azioni"
                :id="`tabella-in-corso-riga-${props.row.id}-sidebar-azioni`"
                @click="sidebarActionInCorso(props.row.id, props.row)"
              />
            </div>
          </span>

          <!-- Column: standard -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
          </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
          <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
              Visualizzazione da 1 a 
              </span>
              <b-form-select
              v-model="pageLength"
              :options="['10','25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> di {{ props.total }} righe </span>
          </div>
          <div>
              <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
              >
              <template #prev-text>
                  <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                  />
              </template>
              <template #next-text>
                  <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                  />
              </template>
              </b-pagination>
          </div>
          </div>
      </template>
      </vue-good-table>

    </b-card>

    <b-modal
      ref="cambio-utente-modal"
      size="md"
      centered
      hide-footer
      title="Selezione attività utente"
      modal-class="modal-primary"
    >
      <b-form-select
        v-model="campio_employee"
        :options="options_campio_employee"
        @change="cambioEmployee()"
      />
    </b-modal>

    <b-modal
      ref="edit-modal"
      size="lg"
      centered
      hide-footer
      title="Modifica Attività"
      modal-class="modal-primary"
      @hide="reseteditModal"
    >
      <b-card-text>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Data *" label-for="date">
                  <validation-provider
                  #default="{ errors }"
                  name="Data"
                  rules="required"
                  >
                    <b-form-datepicker
                      id="date"
                      v-model="campiform.date"
                      dateFormat="YYYY-MM-DD"
                      :min="date_min"
                      start-weekday="1"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Ora *" label-for="time_tagliata">
                  <validation-provider
                  #default="{ errors }"
                  name="Ora"
                  rules="required"
                  >
                    <b-form-input 
                      type="time"
                      id="time_tagliata"
                      v-model="campiform.time_tagliata"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Priorità *" label-for="priority_id">
                  <validation-provider
                  #default="{ errors }"
                  name="Priorità"
                  rules="required"
                  >
                    <b-form-select
                      v-model="campiform.priority_id"
                      :options="options_priorita"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tipo *" label-for="type_id">
                  <validation-provider
                  #default="{ errors }"
                  name="Tipo"
                  rules="required"
                  >
                    <b-form-select
                      v-model="campiform.type_id"
                      :options="options_type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Assegnato a *" label-for="assigned_to">
                  <validation-provider
                  #default="{ errors }"
                  name="Assegnato a"
                  rules="required"
                  >
                    <b-form-select
                      v-model="campiform.assigned_to"
                      :options="options_utenti_agentscout"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <div v-if="campiform.related_to == 'Lead'">
                  <b-form-group label="Relazionato a Lead *" label-for="related_to">
                    <b-form-input
                      :value="campiform.nome_ricerca"
                      disabled
                    />
                  </b-form-group>
                </div>
                <div v-else-if="campiform.related_to === 'Customer'">
                  <b-form-group label="Relazionato a Lead *" label-for="related_to">
                    <b-form-input
                      :value="campiform.nome_ricerca"
                      disabled
                    />
                  </b-form-group>
                </div>
                <div v-else>
                  <b-form-group label="Relazionato a Agente *" label-for="related_to">
                    <b-form-input
                      :value="campiform.nome_ricerca"
                      disabled
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group label="Descrizione *" label-for="description">
                  <validation-provider
                  #default="{ errors }"
                  name="Descrizione"
                  rules="required"
                  >
                    <b-form-input
                      id="Descrizione"
                      v-model="campiform.description"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-button
              variant="primary"
              type="submit"
              :disabled="action_press"
              @click.prevent="validationForm()"
            >
              <div v-if="action_press">Aggiornamento in corso <b-spinner small class="ml-1" /></div>
              <div v-else>Salva</div> 
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar_in_corso"
      bg-variant="white"
      title="Attività"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">

          <b-row cols="1">
            <b-col class="riga_sidebar pt-2"><h4>{{ infoSideBarInCorso.dati.descrizione }}</h4></b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="editAttivita(infoSideBarInCorso.id, infoSideBarInCorso.dati)"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="cambioStatoAttivita(infoSideBarInCorso.id,1)"
          >
            <feather-icon icon="CheckIcon" /> Fatto
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="cambioStatoAttivita(infoSideBarInCorso.id,2)"
          >
            <feather-icon icon="RefreshCwIcon" /> In Corso
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="cambioStatoAttivita(infoSideBarInCorso.id,3)"
          >
            <feather-icon icon="XIcon" /> Annulla
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
            @click="cancellaAttivita(infoSideBarInCorso.id)"
          >
            <feather-icon icon="Trash2Icon" /> Cancella
          </b-button>
        </div>

      </div>
    </b-sidebar>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import it from 'vee-validate/dist/locale/it.json'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BForm, BFormGroup, BInputGroup, BFormInput, BFormDatepicker, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip, BModal, BTabs, BTab, BTable, BOverlay, VBPopover
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import {
  required, email, digits, length, min
} from '@validations'
//import moment from 'moment'
import TooltipTextActivities from '@core/utils/TooltipTextActivities.vue' 

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BForm,
    BFormInput,
    BFormDatepicker, 
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton, 
    BModal, 
    BTabs, 
    BTab, 
    BTable, 
    BOverlay,

    TooltipTextActivities,
  },
  props: {
    related_to: String,
    dati_lead: Object,
    dati_customer: Object,
    dati_agent: Object
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      userData: {},

      campiform: {

      },
      options_priorita: [
        { value: 1, text: 'Urgente' }, 
        { value: 2, text: 'Alta' },
        { value: 3, text: 'Media' },
        { value: 4, text: 'Bassa' },
      ],
      options_type: [
        { value: 1, text: 'Assistenza' }, 
        { value: 2, text: 'Vendita' },
        { value: 3, text: 'Recupero crediti' },
      ], 
      options_utenti_agentscout: [],
      required,
      email,
      digits,
      length,
      min,
      action_press: false,

      isLoading: true,
      emptystate: true, 
      pageLength: 10,
      dir: false,

      date_min: '',
      campio_employee: null,
      options_campio_employee: [],

      /* InCorso */
      columns: [
        {
          label: 'Data / Ora',
          field: 'date',
          width: '150px',
          filterOptions: {
            enabled: true,
            placeholder: 'YYYY-MM-DD',
          },
        },
        {
          label: 'Proprità',
          width: '90px',
          field: 'priority_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Tutti',
            filterDropdownItems: [
              { value: 1, text: 'Urgente' }, 
              { value: 2, text: 'Alta' },
              { value: 3, text: 'Media' },
              { value: 4, text: 'Bassa' },
            ],
          },
        },
        {
          label: 'Tipo',
          field: 'type_id',
          width: '150px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tutti',
            filterDropdownItems: [
              { value: 1, text: 'Assistenza' }, 
              { value: 2, text: 'Vendita' },
              { value: 3, text: 'Recupero crediti' },
            ],
          },
        },
        {
          label: 'Relazionato a',
          field: 'nome_ricerca',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca per Nome Azienda / Agente',
          },
        },
        {
          label: 'Descrizione',
          field: 'description',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },

        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          "status_id":"1",
        },
        sort: [
            {
            field: 'date',
            type: 'asc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {
          "status_id":"1",
        },
        sort: [
            {
            field: 'date',
            type: 'asc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'ref_code', text: 'Licenza' },
        { value: 'customer.billing.company_name', text: 'Cliente' },
        { value: 'subscription.created_at', text: 'Data d\'inizio' },
        { value: 'status.name', text: 'Stato' }
      ],
      
      infoSideBarInCorso: {
        id: '',
        dati: {},
      },



      
      Caricato: false,
      errore_caricamento: false,
      windowWidth: 0,
      
      datiEditModal: {},

    }

  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'danger' : 'light-danger',
        'orange' : 'light-warning',
        'primary' : 'light-info',
        'success' : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    //in caso di resize del browser -> ricalcolo della larghezza della finestra
    window.onresize = () => {
      //this.checkWindowsSizeAndResponsiveGoodTable();
    }
  },
  created() {
    //nascondi ultima colonna a tutti tranne ai SuperAdmin (selezione "Assegnato a")
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData);

    //caricamento dei dati
    this.loadItems();

    this.Caricato = true;
    this.errore_caricamento = false;

    //edit attività
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.campiform.date = today;
    this.date_min = today;

    this.listaEmployee();
    //auto selezione proprio utente nel select
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.campiform.assigned_to = this.userData.id
    this.campiform.related_to = 'Lead';

    //select per selezione utente
    this.campio_employee = this.userData.id;

    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })
  },
  methods: {
    listaEmployee() {
      this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee"}&sort[]={"field":"name","type":"asc"}&page=1&perPage=100&status=active').then(response => { 
        if(response.data.statusCode == 200){
          //ok
          let i = 0;
          while (i < response.data.reply.meta.total) {
            this.options_utenti_agentscout[i] = { value: response.data.reply.data[i].id, text: response.data.reply.data[i].name+' '+response.data.reply.data[i].surname },
            
            i++;
          }

          this.options_campio_employee = this.options_utenti_agentscout;

        } else {
          //lista manuale ... lascio inalterata
        }      
      })
    },
    openModelSelezioneAttivitaUtente(){
      this.$refs['cambio-utente-modal'].show()
    },
    cambioEmployee(){
      console.log(this.campio_employee)

      //aggiungi filtro per employee selezionato
      this.serverParams.columnFilters.assigned_to = this.campio_employee;

      //chiudi modal
      this.$refs['cambio-utente-modal'].hide()

      //caricamento dei dati
      this.loadItems();
    },
    reseteditModal(){
      this.campiform = { }
    },
    gotoqui(tipo, id){
      if(tipo == 'lead'){
        this.$router.push('../crm/leads/show/'+id);
      } else if(tipo == 'cliente'){
        this.$router.push('../crm/customers/show/'+id);
      } else {
        this.$router.push('../crm/agenti/show/'+id);
      }
    },

    sidebarActionInCorso(id, dati) {
      this.infoSideBarInCorso = { id: id, dati: dati, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar_in_corso')
    },
    editAttivita(id, dati) {
      this.campiform = {
        id: id, 
        date: dati.date,
        time_tagliata: dati.time,
        time: '',
        priority_id: dati.priorita.id,
        type_id: dati.type_id,
        assigned_to: dati.assigned_to,
        related_to: dati.related_to,
        description: dati.description,
        nome_ricerca: dati.nome_ricerca,
      }

      this.$refs['edit-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log("Speriamo bene --------------------------")
          //console.log(this.campiform)

          //cancella campi superflui per il salvataggio (usati solo per la vista)
          delete(this.campiform.lead_azienda);
          delete(this.campiform.customer_azienda);
          delete(this.campiform.agent_nome);
          
          this.campiform.time = this.campiform.time_tagliata+':00'; //aggiungo i secondi che nel DB ci vogliono!

          delete(this.campiform.time_tagliata);

          //console.log("DOPO Speriamo bene --------------------------")
          //console.log(this.campiform)

          this.$http.post('api/crm/activities/card/'+this.campiform.id, 
            this.campiform
          ).then(response => { 
            //console.log("risposta server --------")
            //console.log(response.data)

            //cancellazione campo epr eventuale prossima modifica
            delete(this.campiform.time);

            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
              //risposta positiva

              //chiudi sidebar
              this.$refs['edit-modal'].hide();
              //chiudi sidebar
              this.$refs.sidebar_azioni.hide(); 

              this.$swal({
                icon: 'success',
                title: response.data.reply.reply,
                //text: 'operazione effettuata correttamente',
                confirmButtonText: 'chiudi',
                customClass: {
                confirmButton: 'btn btn-success',
                },
              })

              //aggiorna tabella
              this.loadItems();           

          } else {
              //risposta negativa (errore sul server)
              if(response.data.reply.message != ''){
              this.$swal({
                  icon: 'warning',
                  title: 'Si è verificato un errore',
                  text: response.data.reply.message,
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
              } else {
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
              }

              this.action_press = false
          }
          }).catch(e => {
            console.log(e);
          });

        }
      })
    },

      
    updateParams(newProps) {
      //visualizza bottone reset
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    clickReset(){
      console.log("clickReset");

      //forza reset filtro colonne
      this.$refs.table_InCorso.reset();

      //forza reset parametri iniziali della tabella
      this.updateParams(this.startServerParams);
      this.loadItems();

      //disattiva bottone "reset"
      this.resetState = 'hidden';
    },


    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      //console.log('cambio ordinamento')
      //console.log(params)
      this.loadItems();
    },
    
    onColumnFilter(params) {
      console.log("in ->>>> onColumnFilter")
      console.log(params)
      
      this.updateParams(params);
      this.loadItems();
    },


    loadItems() {
      this.isLoading = true;
      this.serverParams.columnFilters.status_id = 1;

      //this.userData.id
      if((this.serverParams.columnFilters.assigned_to === undefined) || (this.serverParams.columnFilters.assigned_to == '')){
        //metti l'utente di loggato per visualizzare solo le sue attività
        this.serverParams.columnFilters.assigned_to = this.userData.id;
      }

      this.$http.get('api/crm/activities/list', {
          params: this.serverParams 
          }).then(response => { 
            if(response.data.statusCode == 200){
              //ok
              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;
              
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
            
            //rimuovi il loading dopo il caricamento dei dati nella tabella
            this.isLoading = false;
        
        })

    },
    cambioStatoAttivita(id_riga, id_nuovo_stato){
      this.$swal({
        title: 'Sei sicuro di voler procedere al cambio stato dell\'attività?',
        //text: 'eventuale descrizione ulteriore',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
          //console.log(id_riga+" - "+id_nuovo_stato)

          this.$http.get('api/crm/activities/cambio_stato/'+id_riga+'/'+id_nuovo_stato).then(response => { 
            //console.log("risposta server --------")
            //console.log(response.data)

            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
              //risposta positiva
              this.$swal({
                icon: 'success',
                title: response.data.reply.reply,
                //text: 'operazione effettuata correttamente',
                confirmButtonText: 'chiudi',
                customClass: {
                confirmButton: 'btn btn-success',
                },
              })

              //chiudi sidebar
              this.$refs.sidebar_azioni.hide();

              //aggiorna tabella
              this.loadItems();
              
            } else {
              //risposta negativa (errore sul server)
              this.$swal({
                  icon: 'error',
                  title: 'Non è stato possibile aggiornare lo stato dell\'attività',
                  text: response.data.reply.message,
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
              
            }
          }).catch(e => {
            console.log(e);
          });

      })
    },
    cancellaAttivita(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva dell\'attività?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/activities/delete/'+id_riga)
            .then(response => { 
              console.log(response);

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
  
  }
}
</script>
  
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.riga_sidebar{
  padding-bottom: 5px;
}
</style>